// ** Initial State
const initialState = {
  consignees: [],
  name: null,
  creatingRecord: false,
  recordCreated: false
  }
  
  const consignee = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_CONSIGNEES':
        return { ...state, consignees: action.consignees }
      case 'ADD_CONSIGNEE':
        return { ...state, recordCreated: false, creatingRecord: true }
      case 'CONSIGNEE_ADDED':
        return { ...state, recordCreated: true, creatingRecord: false }
      default:
        return { ...state }
    }
  }

  export default consignee